import MessageMap from '../i18n';

export const ptBR: MessageMap = {
  MISSIONS_MANAGEMENT: {
    TITLE: 'Gerenciamento de missões',
  },
  GlobalMissionsPage: {
    TITLE: 'Missões globais',
    CREATE_MISSION: 'Criar nova missão',
    INFO_CREATE_MISSIONS: 'Criar as missões que estarão disponíveis para a zona.',
    TABLE_PERIOD: 'Período',
    TABLE_IDENTIFICATION: 'Identificação',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Prioridade',
    PRIORITIZATION: 'Priorização',
    INFO_CHANGES: 'Todas as mudanças feitas terão efeitos amanhã.',
    DISCARD_CHANGES: 'Descartar mudanças',
    SAVE_CHANGES: 'Salvar alterações',

    TOPLINE_GAP: 'Topline gap',
    TOPLINE_GAP_DESCRIPTION:
      'Entenda quais itens não estão sendo adquiridos pelo seu cliente e recomende produtos para ajudá-lo a crescer.',
    MARKETPLACE: 'Marketplace',
    MARKETPLACE_DESCRIPTION: 'Ofereça produtos de marketplace.',
    REWARDS: 'Desafios com recompensas',
    REWARDS_DESCRIPTION:
      'Oriente seu cliente a aceitar e participar de desafios e assim ganhar pontos.',
    CREDIT_ACTIVATION: 'Ativação de crédito',
    CREDIT_ACTIVATION_DESCRIPTION: 'Impulsionar o uso de crédito nas compras dos clientes.',
    SERVICES: 'Serviços',
    SERVICES_DESCRIPTION: 'Ofereça novos serviços para nossos clientes.',
    LOCAL: 'Missões locais',
    LOCAL_DESCRIPTION: 'Missões customizadas criadas pela equipe de back office de operações.',

    STATUS_ONGOING: 'Em andamento',
    STATUS_SCHEDULED: 'Agendada',
    STATUS_CONCLUDED: 'Concluída',
    STATUS_EXPIRED: 'Expirada',
    STATUS_PAUSED: 'Pausada',
    PARAMETER: {
      HEAD: 'Limite compartilhado de missões',
      HEAD_SUB_TITLE: 'Defina o número máximo de missões a serem realizadas por cada agente.',
      GLOBAL_MISSIONS_LABEL: 'Missões globais',
      GLOBAL_MISSIONS_TOOLTIP: 'Topline Gap e Marketplace.',
      LOCAL_MISSIONS_LABEL: 'Missões locais',
      LOCAL_MISSIONS_TOOLTIP:
        'As missões locais com maior prioridade são as que serão atribuídas aos agentes.',
      TOTAL_LIMIT_LABEL: 'Total',
    },
    HELP_DIALOG: {
      PARAMETERS_HELPMODAL_TITLE: 'Limite compartilhado de missões',
      PARAMETERS_HELPMODAL_SUBTITLE: 'Excedendo o limite',
      PARAMETERS_HELPMODAL_TEXT:
        'Quando o limite é excedido, as missões de baixa prioridade podem não ser mostradas aos agentes.',
      PARAMETERS_HELPMODAL_SUBTEXT:
        'Para garantir que uma missão seja exibida, torne-a a prioridade máxima.',
    },
    ERROR_TO_LOAD_MISSIONS: 'Não foi possível carregar as missões',

    DISCARD_TITLE: 'Descartar alterações?',
    DISCARD_DESCRIPTION:
      'As alterações serão descartadas imediatamente e esta ação não poderá ser desfeita.',
    CANCEL: 'Cancelar',
    CONFIRM_DISCARD: 'Sim, descartar',
    CHANGES_SUCCESSFULLY_SAVED: 'As alterações foram salvas com sucesso.',
    CHANGES_FAILED_TO_SAVE: `As alterações não puderam ser salvas.`,
    TRY_SAVING_AGAIN: 'Tentar novamente',
  },
  GlobalMissionsStepTwo: {
    INCLUDE_POCS: 'Incluir POCs com',
    EXCLUDE_POCS: 'Excluir POCs com',
    ADD_CONDITION: 'Adicionar condição',
    EQUALS_TO: 'Igual a',
    GREATER_THAN: 'Maior que',
    GREATER_THAN_OR_EQUAL_TO: 'Maior ou igual a',
    LESS_THAN: 'Menos que',
    LESS_THAN_OR_EQUAL_TO: 'Menor ou igual a',
    BETWEEN: 'Entre',
    LISTED_ON: 'Listado em',
    SELECT_CREDIT: 'Crédito',
    SELECT_PERIOD_CREDIT: 'Período sem utilizar o crédito',
    SELECT_AVAIBLE_CREDIT: 'Crédito disponível',
    SELECT_TERMS_CREDIT: 'Aceite dos termos de uso do crédito',

    SELECT_PURCHASE: 'Perfil de compra',
    SELECT_DIGITALIZATION: 'Nível de digitalização',

    SELECT_INFO_POC: 'Informação da POC',
    SELECT_POC: 'POC ID',

    SELECT_CONDITION: 'Selecionar uma condição',
    ACCEPTED: 'Aceito',
    NOT_ACCEPTED: 'Não aceito',

    UPLOAD: 'Carregar um arquivo',
    BLOCK_LIST: 'Block list',

    ENTER_VALIDATION: 'Inserir validação',

    DAYS_PLACEHOLDER: '0',
    PERCENT_MONEY_PLACEHOLDER: '0.00',
    MONEY_PREFIX: '$',
    DAYS_SUFIX: 'dia(s)',
    PERCENT_SUFIX: '%',
    TOOLTIP_DISABLED: 'Algumas condições têm operadores e tipos de validação específicos.',

    PRIORIZATION_CONDITIONS: 'Contato priorizado de cima para baixo.',
    REGISTRY_ON_BLOCK_LIST: 'Registro na block list',
    TOOLTIP_LIMIT_OF_CONDITIONS: 'Limite de condições simultâneas atingido.',
  },
  GlobalMissionsStepThree: {
    SCRIPT_TITLE: 'Roteiro',
    SCRIPT_SUB_TITLE: '(opcional)',
    SCRIPT_PLACEHOLDER:
      'Exemplo: cumprimentar o cliente e informar o motivo da ligação.\nEm seguida, lembre-os sobre os benefícios de comprar diretamente do aplicativo BEES.',
    EFFECTIVENESS_LABEL: 'Critérios de eficácia',
    EFFECTIVENESS_DESCRIPTION:
      'Condições para que esta missão seja considerada bem sucedida após sua conclusão.',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_TITLE: 'Crédito como pagamento',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_SUBTITLE: 'Requer o uso de crédito como método de pagamento.',
    EFFECTIVENESS_CREDIT_ONBOARDING_TITLE: 'Onboarding de crédito',
    EFFECTIVENESS_CREDIT_ONBOARDING_SUBTITLE: 'Requer a aceitação dos termos de uso do crédito.',
    TIME_LIMIT_LABEL: 'Limite de tempo',
    TIME_LIMIT_DESCRIPTION: 'Período para atingir os critérios de eficácia.',
    TIME_LIMIT_WITHIN_THE_MISSION_PERIOD_OPTION: 'Durante o período da missão',
    TIME_LIMIT_SAME_DAY_OF_THE_CALL_OPTION: 'No mesmo dia da chamada',
    TIME_LIMIT_THE_DAY_AFTER_THE_CALL_OPTION: 'No dia seguinte à chamada',
    TIME_LIMIT_CUSTOMIZED_PERIOD_OPTION: 'Período personalizado',
    MINIMUM_AMOUNT_LABEL: 'Valor mínimo pago',
    DAYS_SUFIX: 'dias',
    INCLUDE_DAYS_CHECKBOX: 'Incluir os dias restantes do mês corrente',
    MINIMUM_AMOUNT_ERROR: 'O valor excede o limite definido para esta audiência.',
  },
  Loading: {
    BEELOADING_TEXT: 'Processando…',
  },
  GlobalMissionsSteps: {
    FIRST_STEP: 'Principal',
    SECOND_STEP: 'Audiência',
    THIRD_STEP: 'Conteúdo',
    buttons: {
      CONTINUE: 'Continuar',
      CANCEL: 'Cancelar',
      GO_BACK: 'Voltar',
      FINISH: 'Finalizar',
    },
    inputValidationFeedbacks: {
      ENTER_A_MISSION_NAME: 'Digite um nome de missão',
      MISSION_ALREADY_EXISTS: 'Já existe uma missão com este título, tente outro',
      ENTER_A_DESCRIPTION: 'Insira uma descrição',
      REQUIRED_FIELD: 'Campo obrigatório',
      AFTER_TOMORROW_MESSAGE: 'Insira uma data depois de amanhã',
      AFTER_START_DATE_MESSAGE: 'Insira uma data após o inicio da missão',
      DATE_IN_THE_PAST_MESSAGE: 'Não pode começar no passado',
      SELECT_A_VALID_OPTION: 'Selecione uma opção válida',
      THERE_IS_NO_CONDITION_IN_SECOND_STEP: 'Defina para quais POCs esta missão será elegível',
      REQUIRED_BOTH_FIELDS: 'Ambos os campos são obrigatórios',
    },
  },
  GlobalMissionsCard: {
    START_DATE: 'Início',
    END_DATE: 'Final',
    END_DATE_VALUE: 'Sem data final',
  },
  NewGlobalMissionsPage: {
    OPTIONAL: 'Opcional',
    MISSION_NAME: 'Nome da missão',
    MISSION_NAME_PLACEHOLDER: 'Exemplo: impulso do mercado',
    CATEGORIES: 'Categorias',
    CATEGORIES_PLACEHOLDER: 'Exemplo: Fintech, Marketplace, Recompensas',
    CATEGORIES_REWARDS: 'Recompensas',
    CATEGORIES_SERVICES: 'Serviços',
    END_DATE: 'Data final',
    END_DATE_PLACEHOLDER: 'Insira a data de término',
    START_DATE: 'Data de início',
    START_DATE_PLACEHOLDER: 'Insira a data de início',
    DESCRIPTION: 'Descrição',
    DESCRIPTION_PLACEHOLDER_FIRST_TEXT: 'Digite uma breve descrição para esta missão',
    DESCRIPTION_PLACEHOLDER_SECOND_TEXT:
      'Esta descrição também pode ser exibida na plataforma do agente.',
    WHEN_TO_CALL: 'Quando ligar',
    DAYS_WITHIN_THE_DELIVERY_WINDOW: 'Dias dentro da janela de entrega',
    ANY_DAY: 'Qualquer dia',
    CALL_EVERY: 'Após o primeiro contato, ligar a cada',
    CALL_EVERY_PLACEHOLDER: 'dias',

    SUCCESS_TOAST_MESSAGE: 'A missão foi criada com sucesso e entrará em vigor amanhã.',
    ERROR_TOAST_MESSAGE: 'Não foi possível criar a missão.',
  },
  LOAD_ERROR: {
    MESSAGE: 'Algo deu errado.',
    TRY_AGAIN: 'Tentar de novo',
  },
};
