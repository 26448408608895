/* eslint-disable camelcase */
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { v4 as guid } from 'uuid';
import { getTokenLocalStorage, getEuropeClusterOrUrlParam, getUserInfos } from '../utils/functions';

const axiosInstance = axios.create();

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
  'axios-retry'?: {
    retryCount: number;
  };
}

const axiosIntercept = (): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getTokenLocalStorage();

      const { orgId, country } = getUserInfos();

      config.headers.Authorization = token;
      config.headers.requestTraceId = guid();
      config.headers.country = country;

      if (orgId) {
        config.headers.orgId = orgId;
      }

      config.url = getEuropeClusterOrUrlParam(config.url);

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
};

export { axiosIntercept };
export default axiosInstance;
