import React from 'react';
import { InitialDataWrapper } from 'grow-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { useFeatureToggleService } from 'admin-portal-shared-services';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { ThemeProvider } from '@hexa-ui/theme';
import { WRAPPER_PROJECT_NAME_INSTANCE } from '@config/constants';
import ToggleMockWrapper from './components/toggleMockWrapper/ToggleMockWrapper';
import Router from './Router';
import { EnvProvider } from './components/envProvider/EnvProvider';
import { EnvConfig } from './components/envProvider/EnvProvider.types';

import { IntlProvider } from './i18n';
import { GlobalStyle } from '../theme/GlobalStyle';

export default function App(props: EnvConfig) {
  const { ONCUSTOMER_OPTIMIZELY_KEY } = props;

  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-global-missions-mfe',
    seed: 'bees-hub-global-missions-mfe',
  });

  useFeatureToggleService().loadToggles();

  return (
    <InitialDataWrapper
      projectName={WRAPPER_PROJECT_NAME_INSTANCE}
      optKey={ONCUSTOMER_OPTIMIZELY_KEY}
    >
      <ToggleMockWrapper>
        <ThemeProvider theme="grow">
          <GlobalStyle />
          <EnvProvider env={props}>
            <StylesProvider generateClassName={generateClassName}>
              <IntlProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </IntlProvider>
            </StylesProvider>
          </EnvProvider>
        </ThemeProvider>
      </ToggleMockWrapper>
    </InitialDataWrapper>
  );
}
