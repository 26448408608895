import { useAuthenticationService } from 'admin-portal-shared-services';
import { v4 as guid } from 'uuid';
import { UserConfig, useUserConfigService } from 'grow-shared-services';
import { LOCAL_STORAGE_PORTAL_CONFIG } from '../constants';

export const BASE_NAME = '/beesgrow';

export const getPortalConfig = (): Record<string, string> => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);

  if (!portalLocalStorage) return undefined;

  return JSON.parse(portalLocalStorage);
};

export const getTokenLocalStorage = (): string => {
  return useAuthenticationService().getAuthHeader();
};

export const getRequestTraceId = (): string => {
  return guid();
};

export const getUserInfos = (): UserConfig => {
  const { data } = useUserConfigService().getStoreValue();

  return { ...data };
};

export const getEuropeClusterOrUrlParam = (url: string) => {
  const { country } = getUserInfos();
  const europeCountries = localStorage.getItem('europeCountries');

  if (europeCountries?.includes(country)) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};

const removeUrlPrefix = (url: string) => {
  return url.replace('/api/v1', '');
};

interface Navigation {
  fromButtonName: string;
  toButtonName: string;
  fromURI: string;
  toURI: string;
  uri: string;
  event: React.SyntheticEvent;
  navigate: () => void;
}

export const handleNavigationEvent = ({
  fromButtonName,
  toButtonName,
  fromURI,
  navigate,
  uri,
  event,
}: Navigation) => {
  if (toButtonName !== fromButtonName && uri === fromURI) {
    event.preventDefault();
    const exitPage = new CustomEvent('exitPageBySideMenuEvent', {
      detail: {
        navigate,
        destination: uri,
      },
    });
    window.dispatchEvent(exitPage);
  }
};
