// Local Storage
export const LOCAL_STORAGE_PORTAL_CONFIG = 'portal-config';

// Service Constants
export const SERVICES_PARAMS = {
  vendorIdParam: 'vendorId',
};

// Others
export const WRAPPER_PROJECT_NAME_INSTANCE = 'cx-tlp-portal-admin-mfe';
export const SERVICE_MISSION = '#digital_services';
