import MessageMap from '../i18n';

export const es419: MessageMap = {
  MISSIONS_MANAGEMENT: {
    TITLE: 'Administración de misiones',
  },
  GlobalMissionsPage: {
    TITLE: 'Misiones globales',
    CREATE_MISSION: 'Crear nueva misión',
    INFO_CREATE_MISSIONS: 'Crea las misiones que estarán disponibles para la zona.',
    TABLE_PERIOD: 'Período',
    TABLE_IDENTIFICATION: 'Identificación',
    TABLE_STATUS: 'Estatus',
    TABLE_PRIORITY: 'Prioridad',
    PRIORITIZATION: 'Priorización',
    INFO_CHANGES: 'Todos los cambios realizados entrarán en vigor mañana.',
    SAVE_CHANGES: 'Guardar cambios',
    DISCARD_CHANGES: 'Descartar los cambios',

    TOPLINE_GAP: 'Topline gap',
    TOPLINE_GAP_DESCRIPTION:
      'Comprenda qué artículos no compra su cliente y recomiende productos para ayudarlos a crecer.',
    MARKETPLACE: 'Marketplace',
    MARKETPLACE_DESCRIPTION: 'Ofrezca productos del marketplace',
    REWARDS: 'Desafíos',
    REWARDS_DESCRIPTION:
      'Guíe a su cliente para que acepte y participe en los desafíos y así gane puntos.',
    CREDIT_ACTIVATION: 'Activación de créditos',
    CREDIT_ACTIVATION_DESCRIPTION: 'Impulsar el uso del crédito en las compras de los clientes.',
    SERVICES: 'Servicios',
    SERVICES_DESCRIPTION: 'Ofrezca nuevos servicios a nuestros clientes.',
    LOCAL: 'Misiones locales',
    LOCAL_DESCRIPTION:
      'Misiones personalizadas creadas por el equipo administrativo de operaciones.',

    STATUS_ONGOING: 'En curso',
    STATUS_SCHEDULED: 'Programada',
    STATUS_CONCLUDED: 'Concluida',
    STATUS_EXPIRED: 'Expirada',
    STATUS_PAUSED: 'Pausada',
    PARAMETER: {
      HEAD: 'Límite de misión compartida',
      HEAD_SUB_TITLE: 'Establecer el número máximo de misiones a realizar por cada agente.',
      GLOBAL_MISSIONS_LABEL: 'Misiones globales',
      GLOBAL_MISSIONS_TOOLTIP: 'Topline gap y Marketplace.',
      LOCAL_MISSIONS_LABEL: 'Misiones locales',
      LOCAL_MISSIONS_TOOLTIP:
        'Las misiones locales con mayor prioridad son las que se asignarán a los agentes.',
      TOTAL_LIMIT_LABEL: 'Total',
    },
    HELP_DIALOG: {
      PARAMETERS_HELPMODAL_TITLE: 'Límite de misión compartida',
      PARAMETERS_HELPMODAL_SUBTITLE: 'Exceder el límite',
      PARAMETERS_HELPMODAL_TEXT:
        'Cuando se excede el límite, es posible que las misiones de baja prioridad no se muestren a los agentes.',
      PARAMETERS_HELPMODAL_SUBTEXT:
        'Para asegurarse de que se muestre una misión, conviértala en la máxima prioridad.',
    },
    ERROR_TO_LOAD_MISSIONS: 'No fue posible cargar las misiones',

    DISCARD_TITLE: '¿Descartar los cambios?',
    DISCARD_DESCRIPTION:
      'Los cambios se descartarán inmediatamente y esta acción no se puede deshacer.',
    CANCEL: 'Cancelar',
    CONFIRM_DISCARD: 'Si, descartar',

    CHANGES_SUCCESSFULLY_SAVED: 'Los cambios se han guardado correctamente.',
    CHANGES_FAILED_TO_SAVE: `No se pudieron guardar los cambios.`,
    TRY_SAVING_AGAIN: 'Intentar nuevamente',
  },
  GlobalMissionsStepTwo: {
    INCLUDE_POCS: 'Incluir a los PDV con',
    EXCLUDE_POCS: 'Excluir a los PDV con',
    ADD_CONDITION: 'Agregar condición',
    EQUALS_TO: 'Igual a',
    GREATER_THAN: 'Mayor que',
    GREATER_THAN_OR_EQUAL_TO: 'Mayor o igual que',
    LESS_THAN: 'Menos que',
    LESS_THAN_OR_EQUAL_TO: 'Menos que o igual a',
    BETWEEN: 'Entre',
    LISTED_ON: 'Listado en',
    SELECT_CREDIT: 'Crédito',
    SELECT_PERIOD_CREDIT: 'Periodo sin utilizar el crédito',
    SELECT_AVAIBLE_CREDIT: 'Crédito disponible',
    SELECT_TERMS_CREDIT: 'Aceptación de las condiciones de uso del crédito',

    SELECT_PURCHASE: 'Perfil de compra',
    SELECT_DIGITALIZATION: 'Nivel de digitalización',

    SELECT_INFO_POC: 'Información sobre el PDV',
    SELECT_POC: 'PDV ID',

    SELECT_CONDITION: 'Seleccione una condición',

    ACCEPTED: 'Aceptado',
    NOT_ACCEPTED: 'No aceptado',

    UPLOAD: 'Cargar un archivo',
    BLOCK_LIST: 'Block list',

    ENTER_VALIDATION: 'Ingresar validación',

    DAYS_PLACEHOLDER: '0',
    PERCENT_MONEY_PLACEHOLDER: '0.00',
    MONEY_PREFIX: '$',
    DAYS_SUFIX: 'día(s)',
    PERCENT_SUFIX: '%',
    TOOLTIP_DISABLED: 'Algunas condiciones tienen operadores específicos y tipos de validación.',

    PRIORIZATION_CONDITIONS: 'El contacto se prioriza de arriba hacia abajo.',
    REGISTRY_ON_BLOCK_LIST: 'Registro en la block list',
    TOOLTIP_LIMIT_OF_CONDITIONS: 'Límite de condiciones concurrentes alcanzado.',
  },
  GlobalMissionsStepThree: {
    SCRIPT_TITLE: 'Guión',
    SCRIPT_SUB_TITLE: '(opcional)',
    SCRIPT_PLACEHOLDER:
      'Ejemplo: Saludar al cliente y decirle el motivo de la llamada.\nLuego, recuérdeles los beneficios de comprar directamente desde la aplicación BEES.',
    EFFECTIVENESS_LABEL: 'Criterios de eficacia',
    EFFECTIVENESS_DESCRIPTION:
      'Condiciones para que esta misión se considere exitosa después de su finalización.',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_TITLE: 'Crédito como forma de pago',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_SUBTITLE: 'Requiere el uso de crédito como método de pago.',
    EFFECTIVENESS_CREDIT_ONBOARDING_TITLE: 'Onboarding de crédito',
    EFFECTIVENESS_CREDIT_ONBOARDING_SUBTITLE:
      'Requiere la aceptación de condiciones de uso del crédito.',
    TIME_LIMIT_LABEL: 'Límite de tiempo',
    TIME_LIMIT_DESCRIPTION: 'Periodo para alcanzar los criterios de eficacia.',
    TIME_LIMIT_WITHIN_THE_MISSION_PERIOD_OPTION: 'Durante el periodo de la misión',
    TIME_LIMIT_SAME_DAY_OF_THE_CALL_OPTION: 'El mismo día de la llamada',
    TIME_LIMIT_THE_DAY_AFTER_THE_CALL_OPTION: 'Al día siguiente de la llamada',
    TIME_LIMIT_CUSTOMIZED_PERIOD_OPTION: 'Periodo personalizado',
    MINIMUM_AMOUNT_LABEL: 'Cantidad mínima pagada',
    DAYS_SUFIX: 'días',
    INCLUDE_DAYS_CHECKBOX: 'Incluir los días restantes del mes en curso',
    MINIMUM_AMOUNT_ERROR: 'El valor supera el límite establecido para esta audiencia.',
  },
  Loading: {
    BEELOADING_TEXT: 'Procesando…',
  },
  GlobalMissionsSteps: {
    FIRST_STEP: 'Principal',
    SECOND_STEP: 'Audiencia',
    THIRD_STEP: 'Contenido',
    buttons: {
      CONTINUE: 'Continuar',
      CANCEL: 'Cancelar',
      GO_BACK: 'Volver',
      FINISH: 'Finish',
    },
    inputValidationFeedbacks: {
      ENTER_A_MISSION_NAME: 'Introduce un nombre de misión',
      MISSION_ALREADY_EXISTS: 'Ya existe una misión con este título, prueba con otro',
      ENTER_A_DESCRIPTION: 'Introduce una descripción',
      REQUIRED_FIELD: 'Este campo es obligatorio',
      AFTER_TOMORROW_MESSAGE: 'Introduce una fecha posterior a mañana',
      AFTER_START_DATE_MESSAGE: 'Ingrese una fecha después de comenzar la misión',
      DATE_IN_THE_PAST_MESSAGE: 'No puede comenzar en el pasado.',
      SELECT_A_VALID_OPTION: 'Seleccione una opción válida',
      THERE_IS_NO_CONDITION_IN_SECOND_STEP: 'Definir para qué POC será elegible esta misión',
      REQUIRED_BOTH_FIELDS: 'Los dos campos son obligatorios',
    },
  },
  GlobalMissionsCard: {
    START_DATE: 'Inicio',
    END_DATE: 'Final',
    END_DATE_VALUE: 'Sin fecha de finalización',
  },
  NewGlobalMissionsPage: {
    OPTIONAL: 'Opcional',
    MISSION_NAME: 'Nombre de la misión',
    MISSION_NAME_PLACEHOLDER: 'Ejemplo: impulso del mercado',
    CATEGORIES: 'Categorías',
    CATEGORIES_PLACEHOLDER: 'Ejemplo: Fintech, Marketplace, Recompensas',
    CATEGORIES_REWARDS: 'Recompensas',
    CATEGORIES_SERVICES: 'Servicios',
    END_DATE: 'Fecha final',
    END_DATE_PLACEHOLDER: 'Ingresa la fecha de finalización',
    START_DATE: 'Fecha de comienzo',
    START_DATE_PLACEHOLDER: 'Ingresa la fecha de inicio',
    DESCRIPTION: 'Descripción',
    DESCRIPTION_PLACEHOLDER_FIRST_TEXT: 'Ingrese una breve descripción para esta misión',
    DESCRIPTION_PLACEHOLDER_SECOND_TEXT:
      'Esta descripción también se puede mostrar en la plataforma de agentes.',
    WHEN_TO_CALL: 'Cuando llamar',
    DAYS_WITHIN_THE_DELIVERY_WINDOW: 'Días dentro de la ventana de entrega',
    ANY_DAY: 'Cualquier día',
    CALL_EVERY: 'Después del primer contacto, llamar a cada',
    CALL_EVERY_PLACEHOLDER: 'días',

    SUCCESS_TOAST_MESSAGE: 'La misión se ha creado con éxito y entrará en vigor mañana.',
    ERROR_TOAST_MESSAGE: 'No se puede crear la misión.',
  },
  LOAD_ERROR: {
    MESSAGE: 'Algo salió mal.',
    TRY_AGAIN: 'Volver a intentars',
  },
};
