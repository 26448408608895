import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { isFeatureEnabled } from 'grow-shared-services';
import { BASE_NAME } from '@config/utils/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import useGlobalPermissions from '@hooks/globalPermissions/useGlobalPermissions';
import {
  GROW_ADMIN_BUSINESS_MISSION,
  GROW_ADMIN_BETWEEN_MISSION_MANAGEMENT,
} from '@config/featureToggles';
import * as Styled from './Router.styles';

const GlobalMissionsMainPage = lazy(
  () => import('./pages/globalMissionsMainPage/GlobalMissionsMainPage')
);

const NewGlobalMission = lazy(
  () => import('./pages/globalMissionsMainPage/newGlobalMission/NewGlobalMission')
);

const Router = (): JSX.Element => {
  const isGlobalUser = useGlobalPermissions();

  const isGlobalMissionsEnabled = isFeatureEnabled(GROW_ADMIN_BUSINESS_MISSION);

  const isGrowAdminBetweenMissionManagement = isFeatureEnabled(
    GROW_ADMIN_BETWEEN_MISSION_MANAGEMENT
  );

  const callCorrectlyRoute = () => {
    if (isGlobalMissionsEnabled) {
      return isGlobalUser ? (
        <NewGlobalMission />
      ) : (
        <Navigate to={`${BASE_NAME}/global-missions`} replace />
      );
    }

    return <Navigate to={`${BASE_NAME}/management`} replace />;
  };

  return (
    <Styled.ContainerBody>
      <Suspense
        fallback={
          <Styled.LoadingView>
            <CircularProgress
              style={{ color: '#000000' }}
              data-testid="transition-spinner"
              disableShrink
            />
          </Styled.LoadingView>
        }
      >
        <Styled.Content>
          <Routes>
            <Route
              path={`${BASE_NAME}/global-missions`}
              element={
                isGrowAdminBetweenMissionManagement ? (
                  <GlobalMissionsMainPage data-testid="global-missions-component" />
                ) : (
                  <Navigate to={`${BASE_NAME}/management`} replace />
                )
              }
            />
            <Route
              path={`${BASE_NAME}/global-missions/new-global-mission`}
              element={callCorrectlyRoute()}
            />
          </Routes>
        </Styled.Content>
      </Suspense>
    </Styled.ContainerBody>
  );
};
export default Router;
