import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Work Sans', sans-serif !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow' !important;
  }
`;

export { GlobalStyle };
