import { useHasPermission } from 'admin-portal-shared-services';

const useGlobalPermissions = (): boolean => {
  const hasCampaignsWritePermission = useHasPermission('BeesGrow.Campaign.Write');
  const hasAgentWritePermission = useHasPermission('BeesGrow.Agent.Write');
  const hasBlockListWritePermission = useHasPermission('BeesGrow.BlockList.Write');
  const hasMissionManagementWrite = useHasPermission('BeesGrow.Mission.Write');
  const hasLocalMissionWritePermission = useHasPermission('BeesGrow.LocalMission.Write');

  const hasFlexibleMissionWritePermission = useHasPermission('BeesGrow.FlexibleMission.Write');

  const isGlobalUser = () =>
    hasCampaignsWritePermission &&
    hasAgentWritePermission &&
    hasBlockListWritePermission &&
    hasMissionManagementWrite &&
    hasFlexibleMissionWritePermission &&
    hasLocalMissionWritePermission;

  return isGlobalUser();
};

export default useGlobalPermissions;
