import MessageMap from '../i18n';

export const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  MISSIONS_MANAGEMENT: {
    TITLE: 'Missions management',
  },
  GlobalMissionsPage: {
    TITLE: 'Global missions',
    CREATE_MISSION: 'Create new mission',
    INFO_CREATE_MISSIONS: 'Create the missions that will be available to the zone.',
    TABLE_PERIOD: 'Period',
    TABLE_IDENTIFICATION: 'Identification',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Priority',
    PRIORITIZATION: 'Prioritization',
    INFO_CHANGES: 'All changes made will take effects by tomorrow.',
    SAVE_CHANGES: 'Save changes',
    DISCARD_CHANGES: 'Discard changes',

    TOPLINE_GAP: 'Topline gap',
    TOPLINE_GAP_DESCRIPTION: `Understand where's the customer gap and recommend products to help them grow.`,
    MARKETPLACE: 'Marketplace',
    MARKETPLACE_DESCRIPTION: 'Offer the purchase of marketplace products.',
    REWARDS: 'Rewards challenges',
    REWARDS_DESCRIPTION:
      'Advise the customers to accept and do the available challenges to earn points.',
    CREDIT_ACTIVATION: 'Credit ativation',
    CREDIT_ACTIVATION_DESCRIPTION: `Boost the credit usage on customers' purchases.`,
    SERVICES: 'Services',
    SERVICES_DESCRIPTION: 'Offer new services available for our customers.',
    LOCAL: 'Local missions',
    LOCAL_DESCRIPTION: 'Customized missions created by the operations back office team.',

    STATUS_ONGOING: 'In progress',
    STATUS_SCHEDULED: 'Scheduled',
    STATUS_CONCLUDED: 'Concluded',
    STATUS_EXPIRED: 'Expired',
    STATUS_PAUSED: 'Paused',
    PARAMETER: {
      HEAD: 'Shared limit of missions',
      HEAD_SUB_TITLE: 'Set the maximum number of missions to be performed by each agent.',
      GLOBAL_MISSIONS_LABEL: 'Global missions',
      GLOBAL_MISSIONS_TOOLTIP: 'Topline gap and Marketplace.',
      LOCAL_MISSIONS_LABEL: 'Local missions',
      LOCAL_MISSIONS_TOOLTIP:
        'The local missions with the most priority are the ones that will be assigned to the agents.',
      TOTAL_LIMIT_LABEL: 'Total',
    },
    HELP_DIALOG: {
      PARAMETERS_HELPMODAL_TITLE: 'Shared limit of missions',
      PARAMETERS_HELPMODAL_SUBTITLE: 'Exceeding the limit',
      PARAMETERS_HELPMODAL_TEXT:
        'When the limit is exceeded, the low priority missions may not be shown to the agents.',
      PARAMETERS_HELPMODAL_SUBTEXT:
        'To make sure a mission will be shown, make it the top priority.',
    },
    ERROR_TO_LOAD_MISSIONS: "We couldn't load the missions",

    DISCARD_TITLE: 'Discard the changes?',
    DISCARD_DESCRIPTION: `The changes will be discarded immediately, and this action can’t be undone.`,
    CANCEL: 'Cancel',
    CONFIRM_DISCARD: 'Yes, discard',

    CHANGES_SUCCESSFULLY_SAVED: 'The changes were successfully saved.',
    CHANGES_FAILED_TO_SAVE: `The changes couldn’t be saved.`,
    TRY_SAVING_AGAIN: 'Try again',
  },
  GlobalMissionsStepTwo: {
    INCLUDE_POCS: 'Include POCs with',
    EXCLUDE_POCS: 'Exclude POCs with',
    ADD_CONDITION: 'Add condition',
    EQUALS_TO: 'Equals to',
    GREATER_THAN: 'Greater than',
    GREATER_THAN_OR_EQUAL_TO: 'Greater than or equal to',
    LESS_THAN: 'Less than',
    LESS_THAN_OR_EQUAL_TO: 'Less than or equal to',
    BETWEEN: 'Between',
    LISTED_ON: 'Listed on',

    SELECT_CREDIT: 'Credit',
    SELECT_PERIOD_CREDIT: 'Period without using credit',
    SELECT_AVAIBLE_CREDIT: 'Available credit',
    SELECT_TERMS_CREDIT: 'Acceptance of credit terms of use',

    SELECT_PURCHASE: 'Purchase profile',
    SELECT_DIGITALIZATION: 'Digitalization level',

    SELECT_INFO_POC: 'POC information',
    SELECT_POC: 'POC ID',

    SELECT_CONDITION: 'Select a condition',
    ACCEPTED: 'Accepted',
    NOT_ACCEPTED: 'Not accepted',

    UPLOAD: 'Upload a file',
    BLOCK_LIST: 'Block list',

    ENTER_VALIDATION: 'Enter validation',

    DAYS_PLACEHOLDER: '0',
    PERCENT_MONEY_PLACEHOLDER: '0.00',
    MONEY_PREFIX: '$',
    DAYS_SUFIX: 'day(s)',
    PERCENT_SUFIX: '%',
    TOOLTIP_DISABLED: 'Some conditions have specific operators and validation types.',

    PRIORIZATION_CONDITIONS: 'Contact prioritized from top to bottom.',
    REGISTRY_ON_BLOCK_LIST: 'Registry on block list',
    TOOLTIP_LIMIT_OF_CONDITIONS: 'Limit of concurrent conditions reached.',
  },
  GlobalMissionsStepThree: {
    SCRIPT_TITLE: 'Script',
    SCRIPT_SUB_TITLE: '(optional)',
    SCRIPT_PLACEHOLDER:
      'Example: Greet the customer and tell the reason of the call.\nThen remind them about the benefits of purchasing directly from the BEES App.',
    EFFECTIVENESS_LABEL: 'Effectiveness criteria',
    EFFECTIVENESS_DESCRIPTION:
      'Conditions for this mission to be considered succesful after its completion.',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_TITLE: 'Credit as payment',
    EFFECTIVENESS_CREDIT_AS_PAYMENT_SUBTITLE: 'Requires using credit as payment method.',
    EFFECTIVENESS_CREDIT_ONBOARDING_TITLE: 'Credit onboarding',
    EFFECTIVENESS_CREDIT_ONBOARDING_SUBTITLE: 'Requires the acceptance of credit terms of use.',
    TIME_LIMIT_LABEL: 'Time limit',
    TIME_LIMIT_DESCRIPTION: 'Period to achieve the effectiveness criteria.',
    TIME_LIMIT_WITHIN_THE_MISSION_PERIOD_OPTION: 'Within the mission period',
    TIME_LIMIT_SAME_DAY_OF_THE_CALL_OPTION: 'Same day of the call',
    TIME_LIMIT_THE_DAY_AFTER_THE_CALL_OPTION: 'The day after the call',
    TIME_LIMIT_CUSTOMIZED_PERIOD_OPTION: 'Customized period',
    MINIMUM_AMOUNT_LABEL: 'Minimum amount paid',
    DAYS_SUFIX: 'days',
    INCLUDE_DAYS_CHECKBOX: 'Include remaining days of the current month',
    MINIMUM_AMOUNT_ERROR: 'The value exceeds the limit set to this audience.',
  },
  Loading: {
    BEELOADING_TEXT: 'Processing…',
  },
  GlobalMissionsSteps: {
    FIRST_STEP: 'Main',
    SECOND_STEP: 'Audience',
    THIRD_STEP: 'Content',
    buttons: {
      CONTINUE: 'Continue',
      CANCEL: 'Cancel',
      GO_BACK: 'Go Back',
      FINISH: 'Finish',
    },
    inputValidationFeedbacks: {
      ENTER_A_MISSION_NAME: 'Enter a mission name',
      MISSION_ALREADY_EXISTS: "There's already a mission with this title, try another one",
      ENTER_A_DESCRIPTION: 'Enter a description',
      REQUIRED_FIELD: 'This field is required',
      AFTER_TOMORROW_MESSAGE: 'Enter a date after tomorrow',
      AFTER_START_DATE_MESSAGE: 'Enter a date after starting the mission',
      DATE_IN_THE_PAST_MESSAGE: "It can't start in the past",
      SELECT_A_VALID_OPTION: 'Select a valid option',
      THERE_IS_NO_CONDITION_IN_SECOND_STEP: 'Define for which POCs this mission will be eligible',
      REQUIRED_BOTH_FIELDS: 'Both fields are required',
    },
  },
  GlobalMissionsCard: {
    START_DATE: 'Start',
    END_DATE: 'End',
    END_DATE_VALUE: 'No end date',
  },
  NewGlobalMissionsPage: {
    OPTIONAL: 'Optional',
    MISSION_NAME: 'Mission name',
    MISSION_NAME_PLACEHOLDER: 'Example: Marketplace boost',
    CATEGORIES: 'Categories',
    CATEGORIES_PLACEHOLDER: 'Example: Fintech, Marketplace, Rewards',
    CATEGORIES_REWARDS: 'Rewards',
    CATEGORIES_SERVICES: 'Services',
    END_DATE: 'End date',
    END_DATE_PLACEHOLDER: 'Enter end date',
    START_DATE: 'Start date',
    START_DATE_PLACEHOLDER: 'Enter start date',
    DESCRIPTION: 'Description',
    DESCRIPTION_PLACEHOLDER_FIRST_TEXT: 'Enter a brief description for this mission',
    DESCRIPTION_PLACEHOLDER_SECOND_TEXT:
      'This description may also be displayed in the agents platform.',
    WHEN_TO_CALL: 'When to call',
    DAYS_WITHIN_THE_DELIVERY_WINDOW: 'Days within the delivery window',
    ANY_DAY: 'Any day',
    CALL_EVERY: 'After first contact, call every',
    CALL_EVERY_PLACEHOLDER: 'days',

    SUCCESS_TOAST_MESSAGE:
      'The mission was successfully created, and will take effect by tomorrow.',
    ERROR_TOAST_MESSAGE: 'Unable to create the mission.',
  },
  LOAD_ERROR: {
    MESSAGE: 'Ooops! Something went wrong.',
    TRY_AGAIN: 'Try again',
  },
};
